import React, { useState } from "react";
import Slider from "react-slick";

import PageHeader from "../components/PageHeader";

import SliderItem1 from "../assets/img/alumni/madurai-symposium.jpg";
import SliderItem2 from "../assets/img/alumni/the-dhan-academy-alumni.jpg";

import Brochure1 from "../assets/pdf/Brochure1.pdf";
import Bulletin1 from "../assets/pdf/Bulletin1.pdf";
import Brochure2 from "../assets/pdf/Brochure2.pdf";
import Bulletin2 from "../assets/pdf/Bulletin2.pdf";

import { Collapse } from "antd";

export default function Alumni() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const { Panel } = Collapse;
  const [activeTab, setActiveTab] = useState("Events");
  const changeTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <PageHeader currentPage={"Alumni"} travelPages={["About Us", "Alumni"]} />
      <div className="container py-md-5 py-4 network">
        <Slider {...settings}>
          <div className="slider-item">
            <img src={SliderItem1} alt="" />
          </div>
          <div className="slider-item">
            <img src={SliderItem2} alt="" />
          </div>
        </Slider>
        <h1 className="title-text mt-4 mb-4">
          Welcome to TDA <span>Alumni Community!</span>
        </h1>
        <p className="sub-text">
          Alumni play significant role in the growth of the Academy. They
          reflect the image of the academy in various walks of life. The Academy
          keeps contact with the alumni through its alumni office. The Alumni
          office has been established to contribute to the professional
          management of those institutions which serve the public good,
          especially the ones engaged in various sectors like rural development,
          health, education, women empowerment, etc.
        </p>
        <div className="tab-wrapper mt-4 mb-5">
          <button
            onClick={() => setActiveTab("Events")}
            className={`tab ${activeTab == "Events" ? "active" : ""}`}
          >
            Events
          </button>
          {/* <button
            onClick={() => setActiveTab("Publications")}
            className={`tab ${activeTab == "Publications" ? "active" : ""}`}
          >
            Publications
          </button> */}
          <button
           onClick={() => setActiveTab("Reunions")}
            className={`tab ${activeTab == "Reunions" ? "active" : ""}`}
          >
            <a href="https://thedhanacademy.wordpress.com/archives/"> Alumni corner</a>
          </button>
          <button
          onClick={() => setActiveTab("corner")}
            className={`tab ${activeTab == "corner" ? "active" : ""}`}
          ><a href="https://thedhanacademy.wordpress.com/archives/">
            Alumni LinkedIn Directory</a>
          </button>

        </div>
        {activeTab == "Events" && (
          <div>
            <h2 className="subtitle-text mt-3">Events</h2>
            <p className="mb-2">
              The academy organizes workshops, seminars or conferences with the
              alum to contribute to the growth of the development sector and
              adding value to the Alumni community.
              <br />
              Roundtable on Experiential Learning in Empowering Women at
              Grassroots: Innovative approaches and Challenges September 21, 2019.
            </p>
            <div className="d-flex mb-4">
              <a className="outline-btn" href={Brochure1} target="_blank">
                Brochure
              </a>
              <a className="outline-btn ms-2" href={Bulletin1} target="_blank">
                Bulletin
              </a>
            </div>
            <p className="mb-2">
              “Round Table on Professional Action in Building Resilience at
              Grassroots”, during Madurai Symposium at Madurai, September 24, 2017
            </p>
            <div className="d-flex">
              <a className="outline-btn" href={Brochure2} target="_blank">
                Brochure
              </a>
              <a className="outline-btn ms-2" href={Bulletin2} target="_blank">
                Bulletin
              </a>
            </div>
          </div>
        )}
        {activeTab == "Publications" && (
          <div>
            <h2 className="subtitle-text mt-4">Publications</h2>
            <p>
              We request the alumni to send your articles/ cases or any
              publications to the academy email id with the subject “Alumni
              publication for uploading the website.
            </p>
          </div>
        )}
        {activeTab == "Reunions" && (
          <div>
            <h2 className="subtitle-text mt-4">Reunions</h2>
            <p>
              Reunions are unique and memorable experience of any graduated
              students. The academy organizes alumni reunions. Since students are
              from different states across the country, alumni themselves /
              academy organizes small meetupsstatewise also.
            </p>
          </div>
        )}
        {activeTab == "corner" && (
          <div className="network1 mt-4">
            <h2>Alumni LinkedIn Directory</h2>
            <Collapse defaultActiveKey={["1"]}>
              <Panel
                header={
                  <div>
                    <h4>PDM 1</h4>
                  </div>
                }
                key="1"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mageswaran M</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Vijayakumar R</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Anitha S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Maheswari C</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Kokilavani C</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 2</h4>
                  </div>
                }
                key="2"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2</td>
                      <td>Sreejith N N</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Ajit Kumar Dwivedi</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Kannan G</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jegadesha</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Priya</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Sathisha</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Anitha</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Niranjan</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 3</h4>
                  </div>
                }
                key="3"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>3</td>
                      <td>Archana Bandiwad</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Chandrasekar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Janakiraman N</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Manoj K S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Sasikala</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Kirankumar Patnala</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Renganayagi</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Rajkumar K N</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Sylesh</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Sathishkumar V</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Karthikeyan N</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Nazia</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>

                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 4</h4>
                  </div>
                }
                key="4"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>4</td>
                      <td>Asaithambi T</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Sivaprakash J S</td>
                      <td><a target="_blank" href="https://orcid.org/0009-0009-8993-3344"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Venkata Ramana R</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>

                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 5</h4>
                  </div>
                }
                key="5"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>5</td>
                      <td>Goutam Kumar Mohanty</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Hema P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Kalaiselvi S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Kothai Nayaki N P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Narendran T</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Rameswari Pattnaik</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Ramesh Lingaraddi Radder</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Rhoda A</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Saravanakumar R</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Subadarshee Nayak</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Vishnu G</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 6</h4>
                  </div>
                }
                key="6"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>6</td>
                      <td>Alok Ashish</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Alok Kumar Dubey</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Anamul Haque</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Bhavani Shankar S Gowda</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Girija Prasad Borah</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Gopi Kumar Verma</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Gouri Tikota</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Jeeban Jyoti Mohanty</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Jeyaprakash M</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Jyoti Prakash Ratha</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Kundan Kumar Kaushal</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Manas Kumar Mohapatra</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Pragna Nachiketha</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Ramesh Kumar Maturi</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Sakuntala Kasaragadda</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Sathyanand S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Shaktiprava Maharana</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Shrikant Sharma</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Shrilaxmi B Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Subhash Gautam</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Subrata Kumar Biswal</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Vivek Vishal Singh</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 7</h4>
                  </div>
                }
                key="7"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>7</td>
                      <td>Aravindan M</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/aravindan-moorthy-03445412b/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Balu I</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Bharathi P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Bharathi S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Chandra Guptha B</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Jaganathan K P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Kalaivani K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Kartik Chandra Prusy</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Kumar Alok</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Lalitha R</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Mohammad Asif Hasan</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Murugan K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Pathinettampadian M</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Piyush Balgajanan Awachar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Pooppandi P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rajesh Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Rani O R</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Shyamal Santra</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Subodh Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Suraj Kumar Pandey</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Vinod Kumar Pandey</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 8</h4>
                  </div>
                }
                key="8"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>8</td>
                      <td>Bijaya Kumar Nayak</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Bijay Kumar Toppo</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Frederic Sebastian</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Karthick S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Kishore Kunal</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Kuldeep Borah</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Limlenlal Mate</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Malarvizhy K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Millan Kumar Mishra</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Mohd. Rashid</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Neelabh Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Rutuprasad Sagariya</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Sandeep Bharti</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Soumya Ranjan Swain</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Subhasis Tripathy</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Sumit Vij</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Tushar Krishna</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 9</h4>
                  </div>
                }
                key="9"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>9</td>
                      <td>Bhanu Priya</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Gajanan Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Rajkumar Gupta</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Shreekanth Balachandra Bhat</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Shreerang Kamalakar Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Sree Hari P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Sumeet Soni</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Vinay Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 10</h4>
                  </div>
                }
                key="10"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>10</td>
                      <td>Akhileshwar Singh</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Dushasana Mahanta</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Kunj Bihari Pratap</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Mallesham Edla</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Rajesh Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Ravi Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 11</h4>
                  </div>
                }
                key="11"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>11</td>
                      <td>Amarendra Kumar</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/amarendra-kumar-63996a92?utm_source=share&amp;utm_campaign=share_via&amp;utm_content=profile&amp;utm_medium=android_app"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Anurag Asthana</td>
                      <td><a target="_blank" href="www.linkedin.com/in/anuragasthana123"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Aravind N V</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Pashupati Pandey</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Ravi Raj</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Sandeep Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Shanti Gupta</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Yogesh Chandra Bhatt</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/yogesh-bhatt-944b9b15/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 12</h4>
                  </div>
                }
                key="12"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>12</td>
                      <td>Ishwariya M</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Jitendra Singh</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Liji K K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Rajeev Ranjan</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Seema V Shastri</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Shilpa S Nalavade</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Shweta S Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Smriti Gupta</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Swadeque E K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Vinay Sankar P</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Vinit Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 13</h4>
                  </div>
                }
                key="13"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>13</td>
                      <td>Birajaprasad Singh</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Garima Mishra</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Nagaraj C</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Parth Jayesh Shah</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Prabakaran L</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Santhosh J Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Soumya Prabhakar Bhat</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Subodh Kumar Suman</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Suhas K S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Vignesh V</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>

                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 14</h4>
                  </div>
                }
                key="14"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>14</td>
                      <td>Aashiya Saiyed</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Bangal Anant Bharat Anita</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Charan Hansda</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Hussen Khalandar Saab</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Jitendra Kumar Rai</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Mane Prashant Ramchandra Suvarna</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/prashant-mane-5758a64b/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Mohammadzuber Yusufali Saiyad</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>Muralidhar Das</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 15</h4>
                  </div>
                }
                key="15"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>15</td>
                      <td>Abhisek Panda</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Ankur Chimanbhai Bodalker</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/ankur-bodalker-133a1611b?utm_source=share&amp;utm_campaign=share_via&amp;utm_content=profile&amp;utm_medium=android_app"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Bharathi Narayan Bhat</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Bharati T N</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Bhikale Pravin Maruti Sadhana</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/pravin-bhikale-546b8b75?utm_source=share&amp;utm_campaign=share_via&amp;utm_content=profile&amp;utm_medium=android_app"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Diksha Singh</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Dubey Chetan Ramrekha Bhagyavani</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Gowri A</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Joshi Jaykar Bakulbhai</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Namita Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Ranjana Kumari</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Shwetha M</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>Vijay Prakash</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 16</h4>
                  </div>
                }
                key="16"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>16</td>
                      <td>Anuradha Manjunath Bhandari</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Dinesh kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Jerome J</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Kinjal Arvindbhai Satyapanthi</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Manjunatha</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Narayan Balakrishna Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Naveen Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Paresh S Hegde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>Veerendra C</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 17</h4>
                  </div>
                }
                key="17"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>17</td>
                      <td>Akshay Ashruba Mane</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Anke Narasimhulu</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Divya K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Gowtham S</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Jeyamalini M</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Mahendra Kumawat</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Sayyad Asia</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>Vinayshree Shivaram Gaonkar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 18</h4>
                  </div>
                }
                key="18"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>18</td>
                      <td>Anurag Panigrahi</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Khan Mohd Arif Israil</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Magdum Pallavi Rajkumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Nikam Nitish Balaso</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Patange Akshay Arvind</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Pitabash Sahoo</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Rathod Minesh Shankar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>Shrikande Sunil Anna</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 19</h4>
                  </div>
                }
                key="19"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>19</td>
                      <td>Aditi Khan</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/aditi-khan-wassan/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Bhukya Jitendar Kumar</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Giri Rani Bajarang</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Karpe Manish Ashok</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Kharade Mayur Jaywant</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/mayur-kharade-0b1a8b58/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Kharat Shubham Kailas</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Patil Tejashree Balaso</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Purnajyoti Khanra</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Rajalakshmi S</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/rajalakshmi-sundaram-7058631a6/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Saravana Kumar T</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Saswatik Tripathy</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>Suraj Mondal</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PCM 1</h4>
                  </div>
                }
                key="20"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PCM 1</td>
                      <td>Maner Aakram Dilawar (Pcm)</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>PCM 1</td>
                      <td>Maner Marzina Dilawar (Pcm)</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>PCM 1</td>
                      <td>Muthu Kumar B (Pcm)</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 20</h4>
                  </div>
                }
                key="21"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>20</td>
                      <td>Akshay Bhausaheb Bansode</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Ananya Muskan</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Gulshan Bhatt</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Harish Kursapalli</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Mahadev Manohar Shinde</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Manish Dhakad</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/manish-dhakad-b29563198"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Pranay Sureshraopangul</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Uma D</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Vijayarajan K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PCM 2</h4>
                  </div>
                }
                key="22"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PCM 2</td>
                      <td>Ajithkumar K</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>PCM 2</td>
                      <td>Amit Kumar</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/amit-kumar-2440701b2/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>PCM 2</td>
                      <td>Poosala Thrupathi</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
              <Panel
                header={
                  <div>
                    <h4>PDM 21</h4>
                  </div>
                }
                key="23"
              >
                <table className="table table-striped table-dark table-bordered table-responsive text-center mt-3 w-100">
                  <thead>
                    <tr>
                      <td><strong>Batch</strong></td>
                      <td><strong>Name</strong></td>
                      <td><strong>Linkedin Profile Link</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>21</td>
                      <td>Aditi Kumari</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Bhosale Snehal Uddhav</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Deshmukh Kshitija Tanaji</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Halil Rahuman S</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/halil-rahuman-s-06653b279/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Niranjan</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/niranjan-sanodiya-86558a298/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Priyanka Das</td>
                      <td><a target="_blank" href="https://www.linkedin.com/in/priyanka-das-789215291/"><i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Rajiv Ranjan</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Resham Kumari</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>Soma Bhunia</td>
                      <td><a target="_blank" href="#">  <i className="fab fa-linkedin"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </Panel>
            </Collapse>
          </div >
        )}
      </div >
    </div >
  );
}
